import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";
import UserPassLogin from "./UserPass";

export default function TraccarLogin() {
  return (
    <>
      <UserPassLogin />
      <OnboardGridItem>
        <TextFieldElement label="API Key" type={"text"} name={"apikey"} />
      </OnboardGridItem>
    </>
  );
}
