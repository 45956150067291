import { TextFieldElement } from "react-hook-form-mui";

import { OnboardGridItem } from "../OnboardGridItem";

export default function TTLogin() {
  return (
    <>
      <OnboardGridItem>
        <TextFieldElement label="API Key" type={"text"} name={"apikey"} />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="Provider token"
          type={"text"}
          name={"authorizationcode"}
        />
      </OnboardGridItem>
      <OnboardGridItem>
        <TextFieldElement
          label="DOT Number"
          type={"number"}
          name={"dotnumber"}
        />
      </OnboardGridItem>
    </>
  );
}
